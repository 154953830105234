@import "/src/assets/scss/colors.scss";

.contacts {
    .search-and-stuff {
        width: 80%;
        margin: 0 auto;
        .search {
            width: 33%;
            margin: 2rem auto;
        }
        button {
            font-family: "Montserrat";
            font-weight: 600;
            color: white;
            font-size: 1.5rem;
        }
    }

    #contact-sidebar {
        width: 400px;
        position: fixed;
        top: 0;
        right: -400px;
        height: 100vh;
        z-index: 1100;
        background: #ffffff;
        color: #8d8c8a;
        font-family: "Avenir", "Avenir Next", "Avenir Next";
        transition: all 0.3s;
        box-shadow: -7px -1px 20px 0px rgba(0, 0, 0, 0.2);
        overflow-y: scroll;
        overflow-x: hidden;
        &.active {
            right: 0;
        }
        .header {
            padding: 2rem 1rem;
            background: $pacome-blue;
            display: flex;
            gap: 2rem;
            display: flex;
            align-items: center;
            .close-sidebar,
            .edit-sidebar,
            .delete-sidebar {
                border: none;
                background: transparent;
                width: 30px;
                height: 30px;
                svg {
                    width: 100%;
                    height: 100%;
                    fill: white;
                    transition: all 0.4s;
                    &:hover {
                        fill: $pacome-gold;
                        transition: all 0.2s;
                        rotate: -15deg;
                    }
                }
            }
            h2 {
                color: white;
                font-weight: 600;
                font-family: "Montserrat";
            }
        }
        .content {
            h2 {
                margin: 1rem 2rem;
                font-size: 1.5rem;
                font-weight: 600;
                color: $pacome-blue;
                font-family: "Montserrat";
            }
            ul {
                margin: 0 1rem;
                list-style: none;
                li {
                    margin: 0.5rem 0;
                    color: black;
                    display: flex;
                    justify-content: space-between;
                    font-family: "Montserrat";
                    display: list-item;
                    gap: 0.5rem;
                    span {
                        font-weight: 600;
                    }
                    // &::before {
                    //     content: "•";
                    //     color: $pacome-blue;
                    //     margin-right: 1rem;
                    //     position: absolute;
                    // }
                }
            }
        }
    }
    table {
        button {
            background: none;
            border: none;
            svg {
                width: 30px;
                height: 20px;
                fill: black;
                transition: all 0.4s;
                &:hover {
                    fill: $pacome-gold;
                    transition: all 0.2s;
                    rotate: -15deg;
                }
            }
        }
    }
}
