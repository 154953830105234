@use "/src/assets/scss/colors.scss" as colors;
.nice-button {
    font-family: "Montserrat" !important;
    font-weight: 500 !important;
    font-size: 0.95rem !important;
    padding: 8px 12px !important;
    transition: all 0.2s ease !important;

    &:hover {
        color: colors.$pacome-gold !important;
    }
}

// Styling for the filters panel
.filters-panel {
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: all 0.3s ease;
    
    h5 {
        color: colors.$pacome-blue;
        font-weight: 600;
    }
    
    .form-label {
        font-weight: 500;
        color: #555;
        margin-bottom: 5px;
    }
    
    .form-select, .form-control {
        border-color: #ddd;
        
        &:focus {
            border-color: colors.$pacome-blue;
            box-shadow: 0 0 0 0.2rem rgba(0, 57, 82, 0.25);
        }
    }
    
    .btn-outline-secondary {
        border-color: #ccc;
        color: #666;
        
        &:hover {
            background-color: #f8f9fa;
            color: #333;
        }
    }
    
    .btn-primary {
        background-color: colors.$pacome-blue;
        border-color: colors.$pacome-blue;
        
        &:hover {
            background-color: darken(colors.$pacome-blue, 10%);
            border-color: darken(colors.$pacome-blue, 10%);
        }
    }
}

// Styling for the filter button
.btn-outline-primary.nice-button {
    border-color: colors.$pacome-blue;
    color: colors.$pacome-blue;
    
    &:hover {
        background-color: colors.$pacome-blue;
        color: white !important;
    }
    
    svg {
        margin-right: 5px;
        vertical-align: middle;
    }
}

// Styling for the client dropdown
.z-index-dropdown {
    z-index: 1050 !important;
}

.list-group-item-action {
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
        background-color: #f8f9fa;
    }
    
    &.active {
        background-color: colors.$pacome-blue;
        border-color: colors.$pacome-blue;
        color: white;
        
        &:hover {
            background-color: darken(colors.$pacome-blue, 5%);
        }
    }
}
