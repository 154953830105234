.spinner {
    box-sizing: border-box;
    // width: 60px;
    height: 60px;
    padding: 9px / 2;
    overflow: visible;

    > circle {
        fill: none;
        stroke: currentColor;
        cx: 50%;
        cy: 50%;
        r: 50%;
        // stroke-width: 9px;
        stroke-linecap: round;
        transform-origin: center;

        animateTransform {
            attributename: "transform";
            type: "rotate";
            values: "-90;810";
            keytimes: "0;1";
            dur: "2s";
            repeatcount: "indefinite";
        }

        animate {
            attributename: "stroke-dashoffset";
            values: "0%;0%;-157.080%";
            calcmode: "spline";
            keysplines: "0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0";
            keytimes: "0;0.5;1";
            dur: "2s";
            repeatcount: "indefinite";
        }

        animate {
            attributename: "stroke-dasharray";
            values: "0% 314.159%;157.080% 157.080%;0% 314.159%";
            calcmode: "spline";
            keysplines: "0.61, 1, 0.88, 1; 0.12, 0, 0.39, 0";
            keytimes: "0;0.5;1";
            dur: "2s";
            repeatcount: "indefinite";
        }
    }
}
