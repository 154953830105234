@import "/src/assets/scss/colors.scss";

.required::after {
    content: "*";
    color: red;
}
.billing-popup {
    &.open {
        // display: block;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        pointer-events: all;
        .container {
            top: 50%;
        }
    }
    backdrop-filter: blur(0px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 10000;
    pointer-events: none;
    transition: all 0.3s;
    cursor: pointer;
    .container {
        background: white;
        border-radius: 20px;
        padding: 2.5%;
        overflow-y: scroll;
        width: 85%;
        outline: 10px solid $pacome-blue;
        box-shadow: -20px 19px 7px 12px #00000029;
        height: 80%;
        position: absolute;
        top: -50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: auto;
        overflow-y: scroll;
        transition: all 0.3s;
        h3 {
            font-family: "Montserrat";
            font-weight: 600;
            color: $pacome-blue;
        }
        h4 {
            font-family: "Montserrat";
            font-weight: 500;
            font-style: italic;
        }

        form {
            label {
                font-family: "Montserrat";
                font-weight: 500;
                color: $pacome-blue;
            }
            .single-line {
                .line-flex {
                    & > div {
                        width: 100%;
                    }
                }
            }
        }
    }
}
