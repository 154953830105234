.cardsContainer {
    padding: 2rem 1rem !important;
    background: white;
    .cardShadow {
        // box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
        filter: drop-shadow(1px 2px 6px rgba(0, 0, 0, 0.12));
    }
    & > div > div > p {
        margin: 0;
    }
    .dropdown-elements {
        .dropdown-element {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }
    }

    .accordion {
        ul {
            text-align: left;
        }
        .accordion-buttons {
            display: flex;
        }
    }
}
