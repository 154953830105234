@import "/src/assets/scss/colors.scss";

.secteurs {
    overflow: hidden;
    .cardsContainer {
        padding: 0 !important;
    }
    .lists {
        display: flex;
        grid-template-columns: repeat(2, 1fr);
        height: 70dvh;
        position: relative;
        scroll-behavior: smooth;
        & > div {
            width: 45%;
            overflow-x: scroll;
            height: 100%;
            padding-top: 20px;
            .cards {
                border-radius: 25px;
                background: $pacome-blue;
                margin: 25px 0;
                display: flex;
                box-shadow: 5px 10px 10px 0 rgba(0, 0, 0, 0.1);
                min-height: 150px;
                outline: 0px solid white;
                transition: all 0.1s ease-in-out;
                .owner,
                .sector {
                    width: 50%;
                    padding: 20px 25px;
                    text-align: left;
                }
                .properties,
                .file {
                    width: 50%;
                    padding: 20px;
                    border-radius: 0 23px 23px 0;
                    background: $pacome-gold;
                    text-align: right;
                }
                h4 {
                    color: rgba(255, 255, 255, 0.904);
                    font-size: 1.2rem;
                    margin-bottom: 10px;
                    font-weight: 700;
                    span {
                        text-transform: uppercase;
                        color: white;
                        font-size: 1.3rem;
                    }
                }
                p {
                    margin: 0;
                    color: rgb(255, 255, 255);
                    font-size: 0.9rem;
                }
                input[type="file"]::file-selector-button {
                    background: $pacome-blue;
                    color: white;
                    border: none;
                    padding: 10px 20px;
                    border-radius: 5px;
                    cursor: pointer;
                }
                input[type="file"] {
                    color: white;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }
                input.sectorInput,
                textarea.sectorInput {
                    background: none;
                    color: white;
                    width: 100%;
                    outline: 0px solid white;
                    border: none;
                    border-radius: 5px;
                    padding: 5px 5px;
                    &::placeholder {
                        color: rgba(255, 255, 255, 0.796);
                        font-style: italic;
                        opacity: 1;
                    }
                    &.bordered {
                        outline: 2px solid white;
                    }
                }
                .noFile {
                    // text-align: left;
                    font-weight: 700;
                    font-size: 1rem;
                }
                a {
                    color: white;
                    text-decoration: none;
                    position: relative;
                    transition: all 0.2s ease-in-out;
                    &::after {
                        content: ">";
                        margin-left: 5px;
                    }
                    &::before {
                        content: "";
                        background: white;
                        width: 0px;
                        height: 2px;
                        position: absolute;
                        bottom: -2px;
                        left: 0;
                        transition: all 0.2s ease-in-out;
                    }
                    &:hover::before {
                        width: 100%;
                        background: white;
                    }
                }

                .sector {
                    input {
                        text-transform: uppercase;
                        color: white;
                        font-size: 1.3rem;
                        font-weight: 700;
                    }
                }
            }
        }
        .owners {
            display: flex;
            flex-direction: column;
            h2 {
                order: -100;
            }
            input.mx-auto {
                order: -99;
            }
        }
    }
    .sectorsParent {
        .addSector {
            button {
                margin-top: 30px;
            }
        }
    }
    .cardParent {
        display: flex;
        align-items: center;
        justify-content: center;
        .selection {
            width: 10%;
        }
        .cards {
            width: 90%;
        }
        &.unfocused {
            filter: blur(2px);
            pointer-events: none;
        }
    }
    .cards.selected {
        order: -3;
        outline: 8px solid black !important;
        // border: 2px solid white;

        .owner {
            // outline: 6px solid $pacome-gold;
            border-radius: 25px 0 0 25px;
        }
        .properties {
            // outline: 6px solid $pacome-blue;
        }
    }
    .editing {
        cursor: pointer;
    }
    .selection {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 10%;
        gap: 15px;
        order: -4;
    }
    .two {
        order: 0;
    }
    .table-div {
        & > div {
            margin: 0 1rem;
            width: 100%;
            th {
                color: $pacome-blue;
                font-family: "Montserrat";
            }
            .sector {
                font-style: bold;
            }
        }
    }
}
