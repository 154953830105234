@import "../colors.scss";

.invoices {
    .d-flex.justify-content-end {
        .btn-sm {
            padding: 0.25rem 0.5rem !important;
            font-size: 0.875rem !important;
            line-height: 1.5 !important;
            border-radius: 0.2rem !important;
        }
        
        .btn-outline-primary {
            --bs-btn-color: #003952 !important;
            --bs-btn-border-color: #003952 !important;
            --bs-btn-hover-color: #fff !important;
            --bs-btn-hover-bg: #003952 !important;
            --bs-btn-hover-border-color: #003952 !important;
            color: var(--bs-btn-color) !important;
            border-color: var(--bs-btn-border-color) !important;
        }
        
        .billsActionsIcon {
            width: 16px !important;
            height: 16px !important;
            margin: 0 !important;
        }
    }
    
    .pretty-button {
        background: none;
        border: none;
        svg {
            width: 30px;
            height: 20px;
            fill: black;
            transition: all 0.4s;
            &:hover {
                fill: $pacome-gold;
                transition: all 0.2s;
                rotate: -15deg;
            }
        }
    }
    .search-and-stuff {
        width: 80%;
        margin: 0 auto;
        .search {
            width: 33%;
            margin: 2rem auto;
        }
        button {
            font-family: "Montserrat";
            font-weight: 600;
            color: white;
            font-size: 1.5rem;
        }
    }

    table,
    .contactTable {
        // width: 90% !important;
        thead {
            tr {
                & > :first-child {
                    & > :first-child {
                        margin-left: 2rem;
                    }
                }
            }
        }
        tbody {
            tr > :first-child {
                margin: 1rem 0 1rem 2rem;
            }
            & > :last-child {
                & > :last-child {
                    margin-bottom: 1rem;
                }
                // dates
                & > :nth-child(3),
                & > :nth-child(4) {
                    width: 10% !important;
                }
                // client
                & > :nth-child(5) {
                    width: 15% !important;
                }
                // reference
                & > :nth-child(6) {
                    width: 15% !important;
                }
                // amounts
                & > :nth-child(7),
                & > :nth-child(8) {
                    width: 10% !important;
                }
            }
            div.status {
                // background: green;
                border-radius: 20px;
                // color: white;
                width: unset;
                margin: 1rem;
                text-align: center;
                font-weight: 600 !important;
            }
            div.gray {
                background: #6f6f6f;
                color: white;
            }
            div.green {
                background: #4caf50;
                color: white;
            }
            button:not(.btn-outline-primary):not(.btn-outline-success):not(.btn-outline-warning) {
                // background: none;
                // border: none;
                svg {
                    width: 30px;
                    height: 20px;
                    fill: black;
                    transition: all 0.4s;
                    &:hover {
                        fill: $pacome-gold;
                        transition: all 0.2s;
                        rotate: -15deg;
                    }
                }
            }
        }
    }

    .contactTable,
    productTable {
        width: 100%;
        thead {
            tr {
                & > :first-child {
                    & > :first-child {
                        margin-left: 2rem;
                    }
                }
            }
        }
    }
    .billing-popup .container {
        max-width: unset !important;
    }
    form {
        .input-group-append {
            display: flex;
            flex-wrap: nowrap;
            button:not(.btn-outline-primary):not(.btn-outline-success):not(.btn-outline-warning):hover {
                svg {
                    fill: white;
                }
            }
        }
    }
    .document-creation {
        margin: 2rem auto;
        // outline: 1px solid $pacome-blue;
        .buttons {
            button:not(.btn-outline-primary):not(.btn-outline-success):not(.btn-outline-warning) {
                color: $pacome-blue;
                background: white;
                border: none;
                margin-right: 1rem;
                transition: all 0.4s;
                &:hover {
                    background: $pacome-blue;
                    color: white;
                    transition: all 0.2s;
                }
            }
        }
        .document {
            table, .document-table {
                width: 100%;
                margin: 2rem auto;
                thead {
                    background: $pacome-blue;
                    tr {
                        th {
                            & > :first-child {
                                margin: 1rem 0;
                            }
                        }
                        .code {
                            width: 10%;
                        }
                        .labl {
                            width: 30%;
                        }
                        .desc {
                            width: 5%;
                        }
                        .qty {
                            width: 5%;
                        }
                        .unit {
                            width: 10%;
                        }
                        .pric {
                            width: 8%;
                        }
                        .tax {
                            width: 8%;
                        }
                        .ttc {
                            width: 8%;
                        }
                        .act {
                            width: 8%;
                        }
                    }
                    & tr > :first-child > :first-child {
                        margin-left: 2rem;
                    }
                }
                tbody {
                    tr {
                        td {
                            width: unset !important;
                            .product-input {
                                width: 100%;
                                font-size: 0.9rem;
                                border: none;
                            }
                            &.desc-btn {
                                width: 100%;
                                height: 100%;
                                button:not(.btn-outline-primary):not(.btn-outline-success):not(.btn-outline-warning) {
                                    background: white;
                                    border: none;
                                    margin: 0 auto;
                                    &:hover {
                                        background: $pacome-blue;
                                        color: white;
                                    }
                                }
                            }
                            .description-dropdown {
                                position: absolute;
                                z-index: 1000;
                                background: white;
                                border: 1px solid #ccc;
                                padding: 10px;
                                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                                width: 350px;
                            }
                        }
                        .comment,
                        .section {
                            font-weight: 500;
                        }
                        &:hover {
                            background: unset;
                            cursor: unset;
                        }
                    }
                }
            }
        }
    }

    .billsActionsIcon {
        width: 16px;
        height: 16px;
        margin: 0;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
            color: $pacome-gold;
        }
    }
}
