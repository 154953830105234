@import "/src/assets/scss/colors.scss";

.overlay-modal {
    .modal {
        width: 100dvw;
        height: 100dvh;
        overflow: hidden;
        .modal-confirm {
            width: 40dvw;
            max-width: unset;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            .modal-content {
                padding: 0;
                flex-direction: row;
                overflow: hidden;
            }
            .modal-header {
                aspect-ratio: 1;
                flex-basis: 1;
                flex: 1 0 auto;
                height: auto;
                margin: 0;
                border-radius: 0px;
                &:before {
                    content: "";
                    display: block;
                    padding-top: 100%;
                }
            }
            .modal-body {
                padding: 2rem 0;
                margin: 0 2rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                h4 {
                    font-family: "Montserrat";
                    color: $pacome-blue;
                    font-size: 1.5rem;
                    font-weight: 800;
                    text-align: left;
                }
                p {
                    text-align: left;
                    font-size: 1rem;
                    margin: 0 0 1rem 0;
                    white-space: pre-line;
                }
                .buttons {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    gap: 2rem;
                    flex-wrap: wrap;
                    button {
                        // width: 40%;
                        padding: 0.4rem 2rem;
                        border-radius: 0;
                        background: transparent !important;
                        border: 2px solid $pacome-blue;
                        color: $pacome-blue;
                        font-weight: 800;
                    }
                }
            }
        }
    }
}
