.booking-inventory-grid {
    margin-top: 0;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "download download"
        "entry out";
    text-align: center;
    justify-content: center;
    h2 {
        padding: 1rem 0;
    }

    .form-group {
        flex-direction: column;
        align-content: center;
        text-align: center;
        align-items: center;
        gap: 0.5rem;
    }

    .in {
        grid-area: entry;

        h4 {
            width: 200%;
        }
    }
    .out {
        grid-area: out;

        h4 {
            visibility: hidden;
        }
    }
    .booking-inventory-download {
        grid-area: download;
        width: 50%;
    }
}
.booking-inventory-download {
    margin: 20px auto;
    width: 80%;
}
