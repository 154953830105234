@import "/src/assets/scss/colors.scss";

.table-maxxing {
    margin: 2rem auto 0;
    width: 80%;
    overflow: hidden;
    margin-top: 20px;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    thead {
        background: $pacome-blue;
        tr {
            th {
                color: white !important;
                padding: 15px 20px;
                font-weight: 600;
                text-align: left;
                border: none;
                font-family: "Montserrat";

                span {
                    display: block;
                    font-weight: 800;
                    margin: 0;
                }

                &.full {
                    width: 30%;
                }
                &.less {
                    width: 15%;
                }
                &.lesser {
                    width: auto;
                }
                &.actions {
                    width: 120px;
                }

                &:first-child span {
                    margin-left: 2rem;
                }
            }
        }
    }

    tbody {
        tr {
            background: white;
            transition: all 0.2s;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);

            &:hover {
                background: rgba($pacome-blue, 0.05);
                transform: translateY(-2px);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
            }

            // Credit note styling
            &.credit-note-row {
                background-color: rgba(#f0ad4e, 0.05);
                border-left: 3px solid #f0ad4e;
                
                &:hover {
                    background-color: rgba(#f0ad4e, 0.1);
                }
                
                .indent-marker {
                    color: #f0ad4e;
                    font-weight: bold;
                    margin-right: 4px;
                }
                
                .status {
                    background-color: #f0ad4e !important;
                    color: white !important;
                }
            }

            td {
                padding: 12px 20px;
                color: #2c3e50;
                font-family: "Montserrat";
                border: none;
                vertical-align: middle;

                &.full {
                    width: 30%;
                }
                &.less {
                    width: 15%;
                }
                &.lesser {
                    width: auto;
                }

                &:first-child {
                    padding-left: 2rem;
                }

                .status {
                    display: inline-block;
                    padding: 4px 8px;
                    border-radius: 10px;
                    font-weight: 600;
                    text-align: center;
                    font-size: 0.8em;
                    min-width: 80px;
                    margin: 0.5rem 0;

                    &.gray {
                        background: #ccc;
                        color: #333;
                    }
                    &.green {
                        background: #2ecc71;
                        color: white;
                    }
                }

                &.actions {
                    width: 120px;
                    padding-right: 20px;

                    .d-flex {
                        display: inline-flex !important;
                        gap: 8px;
                        justify-content: flex-end;

                        .pretty-button {
                            background: none;
                            border: none;
                            cursor: pointer;
                            color: $pacome-blue;
                            transition: all 0.2s;
                            padding: 4px;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &:hover {
                                background: rgba($pacome-blue, 0.1);
                                transform: translateY(-1px);
                            }

                            &:disabled {
                                opacity: 0.5;
                                cursor: not-allowed;
                                &:hover {
                                    background: none;
                                    transform: none;
                                }
                            }

                            &.disabled {
                                opacity: 0.5;
                                cursor: not-allowed !important;
                                pointer-events: none;
                            }

                            &.btn-info {
                                color: #5bc0de;
                                &:hover {
                                    background: rgba(#5bc0de, 0.1);
                                }
                            }

                            &.validate-btn {
                                color: #4caf50;
                                font-weight: bold;
                                &:hover {
                                    background: rgba(#4caf50, 0.1);
                                }
                            }

                            &.credit-note-btn {
                                color: #f0ad4e;
                                font-weight: bold;
                                &:hover {
                                    background: rgba(#f0ad4e, 0.1);
                                }
                            }

                            svg {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
            }

            &:first-child td:first-child {
                margin-top: 1rem;
            }
            &:last-child td:last-child {
                margin-bottom: 1rem;
            }
        }
    }
}

.contactsPopUp {
    width: 60% !important;
    .contactTable {
        width: 80% !important;
        margin-top: 2rem;
        tbody {
            tr td {
                padding: 0.6rem;
            }
        }
    }
}

table {
    margin: 0 auto;
    width: 100%;
    padding: 0;
    outline: 10px solid $pacome-blue;
    border-radius: 30px;
    box-shadow: -18px 17px 20px 10px rgb(0 0 0 / 15%);
    overflow: hidden;
    border-collapse: collapse;
    background: white;

    thead {
        background: $pacome-blue;
        tr {
            th {
                padding: 16px 8px;
                text-align: left;
                vertical-align: middle;
                font-family: "Montserrat";
                font-weight: 600;
                font-size: 0.9rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.code-column {
                    width: 80px;
                }
                &.full {
                    padding-left: 16px;
                    padding-right: 16px;
                }
                &.less {
                    width: 50px;
                }
                &.lesser {
                    width: 45px;
                    // text-align: right;
                }
                &.unit-column {
                    width: 30px;
                    text-align: center;
                }
                &.btn-column {
                    width: 32px;
                    text-align: center;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 16px 8px;
                color: #2c3e50;
                font-family: "Montserrat";
                font-size: 0.9rem;
                vertical-align: middle;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.code-column {
                    width: 110px !important;
                }
                &.full {
                    padding-left: 16px;
                    padding-right: 16px;
                }
                &.less {
                    width: 50px;
                }
                &.lesser {
                    width: 45px;
                    text-align: right;
                }
                &.unit-column {
                    width: 30px;
                    text-align: center;
                }
                &.btn-column {
                    width: 32px;
                    text-align: center;
                }

                .btn-primary {
                    opacity: 1;
                    padding: 4px;
                    margin: 0;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 24px;

                    svg {
                        width: 14px;
                        height: 14px;
                    }
                }
            }
        }
    }
}

// Make sure the table is responsive
.table-responsive {
    overflow-x: auto;
    margin: 0 -1rem;
    padding: 0 1rem;
    -webkit-overflow-scrolling: touch;
}
