@use "sass:color";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
/* ---------------------------------------------------
    COLORS
----------------------------------------------------- */
$transparency: #0000;

$primary: #003952;
$secondary: #faba79;
$tertiary: #003952;
$tertiary-lighter: #4c70b0;
$lighter: #fff;
$light: #f6f6f6;
$darker-light: #e6e6e6;
$dark: #8d8c8a;
$black: #363538;
$blackest: #1e1e1f;
$danger: #ff4747;
$success: #71c016;
$warning: #f5a623;
$pacome-blue: #003952;
$pacome-gold: #faba79;

$rent: #bf54ea;
// $Rent-Client: #003952;
$gdf: #8d674f;
$airbnb: #ff5a60;
$booking: #0085c6;
$homeaway: #ff8a00;
$back: #fff;

$theme-colors: (
  "primary": #003952,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "light": $light,
  "dark": $dark,
  "black": $black,
  "blackest": $blackest,
  "warning": $warning,
  "success": $success,
  "rent": $rent,
  // "Rent-Client": $Rent-Client,
  "gdf": $gdf,
  "back": $back,
  "primary-new": #003952,
  "secondary-new": #faba79
);

$accordion-border-color: $secondary;
$accordion-button-active-bg: $primary;
$accordion-button-active-color: white;
$accordion-button-focus-border-color: #003952;
$accordion-button-focus-box-shadow: none;

@import "~bootstrap/scss/bootstrap.scss";

@font-face {
  font-family: "Avenir Next";
  src:
    local("Avenir Next"),
    url(/fonts/AvenirNextLTPro-It.otf) format("opentype");
}

@font-face {
  font-family: "Avenir Next Bold";
  src:
    local("Avenir Next Bold"),
    url(/fonts/AvenirNextLTPro-Bold.otf) format("opentype");
}

html,
body,
#root,
#app,
#app > div {
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow-x: -moz-hidden-unscrollable;
}

/* ---------------------------------------------------
    COLOR STYLE
----------------------------------------------------- */
.warning h5 {
  color: $warning;
}

.danger {
  color: $danger;
}

.success {
  color: $success;
}

.light {
  color: $light;
}

.dark {
  color: $dark;
}

.black {
  color: $black;
}

.blue {
  color: $pacome-blue;
}

.airbnb {
  color: $airbnb;
}

.booking {
  color: $booking;
}

.homeaway {
  color: $homeaway;
}

.rent {
  color: $rent;
}

.warning-bg {
  background-color: $warning !important;
}

.danger-bg {
  background-color: $danger !important;
}

.success-bg {
  background-color: $success !important;
}

.dark-bg {
  background-color: $dark !important;
}

.bg-airbnb {
  background-color: $airbnb !important;
}

.bg-booking {
  background-color: $booking !important;
}

.bg-homeaway {
  background-color: $homeaway !important;
}

.bg-back {
  background-color: $back !important;
}

.bg-rent {
  background-color: $rent !important;
}

/* ---------------------------------------------------
    TEXT STYLE
----------------------------------------------------- */

label small {
  color: dimgray;
}

/* ---------------------------------------------------
    BTN STYLE
----------------------------------------------------- */
.w-48 {
  width: 48% !important;
}

.btn-light {
  color: $black;
  background-color: $light;
  border-color: $dark;
}

.btn-light:hover {
  color: $light;
  background-color: $dark;
  border-color: $light;
}

.btn-dark {
  color: $light;
  background-color: $dark;
  border-color: $dark;
}

.btn-dark:hover {
  color: $secondary;
  background-color: $black;
  border-color: $dark;
}

.btn-tertiary {
  color: $light;
  background-color: $tertiary;
  border-color: $tertiary;
}

.btn-tertiary:hover {
  color: $light;
  background-color: $tertiary-lighter;
  border-color: $tertiary-lighter;
}

.btn-success {
  color: $light;
  background-color: $success !important;
  border-color: darken($success, 20%);
}

.btn-success:hover {
  color: $light;
  background-color: lighten($success, 20%);
  border-color: $success;
}

.btn-warning {
  color: $light;
  background-color: $warning !important;
  border-color: darken($warning, 20%);
}

.btn-warning:hover {
  color: $light;
  background-color: lighten($warning, 20%);
  border-color: $warning;
}

.btn-danger {
  color: $light;
  background-color: $danger !important;
  border-color: darken($danger, 20%);
}

.btn-danger:hover {
  color: $light;
  background-color: lighten($danger, 20%);
  border-color: $danger;
}

.btn-outline-light {
  color: $light;
  background-color: $transparency;
  border-color: $dark;
}

.btn-outline-light:hover {
  color: $dark;
  background-color: $light;
  border-color: $transparency;
  border-radius: 0;
}

.btn:focus {
  box-shadow: none;
}

/* ---------------------------------------------------
    MODAL DETAILS STYLE
----------------------------------------------------- */
hr:not([size]) {
  margin: 15px 0;
}

.overflow-x-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  max-width: 100%;
  //max-height: 400px;
  margin-bottom: 1vh;

  div {
    img {
      max-width: 100%;
      position: relative;

      &.landscape {
        object-fit: cover;
      }

      &.portrait {
        object-fit: contain;
        max-height: -webkit-fill-available;
      }
    }

    .img-overlay {
      flex: auto;
      width: auto;
      flex-direction: row;
      display: flex;
      background-color: $lighter;

      * {
        & + * {
          border-left: solid 1px $dark;
        }

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }

        width: -webkit-fill-available;
      }
    }
  }
}

/* ---------------------------------------------------
    MODAL STYLE
----------------------------------------------------- */

.overlay-modal {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10050;
  cursor: unset;
}

.show {
  display: block;
}

.modal-confirm .modal-content {
  padding: 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
  text-align: center;
  margin: -20px -20px 0;
  border-radius: 5px 5px 0 0;
  padding: 35px;
}

.modal-header.danger {
  background: $danger;
}

.modal-header.success {
  background: $success;
}

.modal-header.warning {
  background: $warning;
}

.modal-header .icon {
  color: $light;
  height: 95px;
  width: auto;
}

.modal-confirm .close {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
  text-shadow: none;
  border-radius: 50%;
  opacity: 0.5;
}

.modal-confirm .modal-body .btn {
  color: #fff;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border-radius: 30px;
  margin-top: 10px;
  padding: 6px 20px;
  min-width: min-content !important;
  border: none;
}

.additionalContacts {
  display: -webkit-inline-box;
  width: 100%;
  height: 36%;
  padding: 1em 1em 0;
  overflow-x: scroll;

  .contactCards {
    height: 95%;
    width: 32%;
    margin-right: 1%;
    background-color: $darker-light;
    overflow: auto;
    line-height: 1em;
    padding: 1em;
    border-radius: 5px;

    p:not(:last-child) {
      line-height: 1em;
    }
  }
}

#description[rows="5"] {
  resize: none;
}
.gauge-wrap {
  place-items: center;
  position: relative;
  margin-top: 4em;
  .arrow {
    &.rotate1 {
      transform: rotate(180deg);
    }
    &.rotate-1 {
      transform: rotate(0);
    }
    &.rotate0 {
      transform: rotate(90deg);
    }
    width: 151px;
    height: 26px;
    position: absolute;
    display: flex;
    z-index: 19;
    left: -5.5%;
    bottom: 15%;
    transform-origin: right;
    &::before {
      content: "";
      background: black;
      width: 36%;
      height: 100%;
      clip-path: polygon(
        0 10px,
        calc(100% - 15px) 10px,
        calc(100% - 15px) 17%,
        100% 50%,
        calc(100% - 15px) 83%,
        calc(100% - 15px) calc(100% - 10px),
        0 calc(100% - 10px)
      );
    }
  }
  .gauge {
    overflow: hidden;
    position: relative;
    width: 200px;
    height: 100px;
    border-top-left-radius: 110px;
    border-top-right-radius: 110px;
    border: 10px solid grey;
    border-bottom: 0;
    margin-bottom: 2em;
    background: green;
    box-shadow: inset 0 1px 19px 1px rgba(0, 0, 0, 0.57);
    :nth-child(n) {
      width: 14.28%;
      height: 100%;
      position: absolute;
    }
    :nth-child(1) {
      width: 14.28%;
      height: 200%;
      transform: rotate(286deg);
      background: red;
      top: 5px;
      left: 50px;
      bottom: 0;
      z-index: 9;
    }
    :nth-child(2) {
      width: 24.28%;
      height: 200%;
      transform: rotate(302deg);
      background: orange;
      top: 0px;
      left: 27px;
      bottom: 0px;
      z-index: 8;
    }
    :nth-child(3) {
      width: 24.28%;
      height: 200%;
      transform: rotate(324deg);
      background: yellow;
      top: 0px;
      left: 40px;
      bottom: 0;
      z-index: 7;
    }

    :nth-child(4) {
      width: 35.28%;
      height: 200%;
      transform: rotate(40deg);
      background: yellow;
      top: 0px;
      left: 97px;
      bottom: 0;
      z-index: 5;
    }
    :nth-child(5) {
      width: 24.28%;
      height: 200%;
      transform: rotate(59deg);
      background: orange;
      top: 5px;
      left: 100px;
      bottom: 0;
      z-index: 13;
    }
    :nth-child(6) {
      width: 24.28%;
      height: 200%;
      transform: rotate(74deg);
      background: red;
      top: 0px;
      left: 142px;
      bottom: 0;
      z-index: 14;
    }
    :nth-child(7) {
      width: 27%;
      height: 40%;
      border-radius: 50%;
      bottom: -20%;
      left: 36%;
      background-color: grey;
      z-index: 20;
      border-top-left-radius: 110px;
      border-top-right-radius: 110px;
    }
  }
  p {
    padding: 1em;
  }
  .container {
    overflow: scroll;
    width: 32%;
    height: 9em;
    overflow-x: hidden;
  }
}

.intervention-table {
  width: 15em;
  // margin: 0 0 0 5%;

  th,
  td {
    border: 2px solid black;
    padding: 1em;
  }
  // tr th:last-child {
  //   padding: 0%;
  // }
  span {
    margin: 0 0 0 5%;
    font-weight: 700;
  }
  td span:first-child,
  td span:last-child,
  br ~ span:is(:not(:nth-child(3))) {
    color: lighten($blackest, 40);
    font-size: 0.9em;
  }
  span + span {
    color: $blackest;
  }
}
/* ---------------------------------------------------
    LOGIN STYLE
----------------------------------------------------- */

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: url("./Images/Background/tobias-tullius-eJeEz6Paz-o-unsplash.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

// .bg-image {
// }

.logo-text {
  font-family: Avenir Next;
}

.full-height {
  height: 100%;
}

.loginBox {
  background-color: rgba($dark, 0.8);
  color: $light;
  height: auto;
  border-radius: 5px;
  text-align: center;
  vertical-align: middle;
  padding: 2%;
}

.logoLogin {
  width: 100%;
  height: auto;
  max-width: 100px;
}

.forgot {
  color: $light;
}

.forgot:link .forgot:visited {
  color: $light;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
@layer old-sidebar {
  #sidebar {
    width: 250px;
    position: fixed;
    top: 0;
    left: -250px;
    height: 100vh;
    z-index: 1100;
    background: $light;
    color: $dark;
    font-family: "Avenir", "Avenir Next", "Avenir Next";
    transition: all 0.3s;
    //overflow-y: scroll;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);

    &.active {
      left: 0;
    }
  }

  #dismiss {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: $dark;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    &:hover {
      background: $dark;
      color: $light;
    }
  }

  .overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;

    &.active {
      display: block;
      opacity: 1;
    }
  }

  #sidebar .sidebar-header {
    padding: 20px;
    background: $black;
    color: $light;
    justify-content: center;

    img {
      height: 50%;
    }

    h3 {
      font-family: Avenir Next;
      font-size: 25px;
      text-align: center;
    }
  }

  #sidebar ul.components {
    padding: 20px 0;
    border-bottom: 1px solid $darker-light;
  }

  #sidebar ul p {
    color: $dark;
    padding: 10px;
  }

  #sidebar ul li a,
  #sidebar ul li button {
    padding: 10px;
    font-size: 1.2em;
    display: block;
    color: $black;
    text-decoration-line: none;
  }

  #sidebar ul li a:hover,
  #sidebar ul li button:hover {
    color: $light;
    // background: $pacome-blue;
    transition: all 0.5s ease-in-out;
  }

  #sidebar ul li.active > a,
  #sidebar ul li.active > button,
  a[aria-expanded="true"] {
    color: $light;
    font-family: "Avenir Next Bold";
    background: $black;
  }

  a[data-toggle="collapse"],
  button[data-toggle="collapse"] {
    position: relative;
  }

  .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  .sidebar-dropdown {
    border: none;
    text-align: left;
    background: none;
  }

  ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: $darker-light;
  }

  ul.CTAs {
    padding: 20px;
  }

  ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  a.download {
    background: #fff;
    color: #75797d;
  }

  a.article,
  a.article:hover {
    background: #6d7fcc !important;
    color: #75797d !important;
  }

  .sidebar-logo {
    width: 100%;
    height: auto;
    max-width: 60px;
  }

  @media screen and (min-width: 950px) {
    .navbar {
      // box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
      max-width: 100%;
      height: 4vh;
    }
  }

  .navbar {
    // box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    max-width: 100%;
  }
}

/* ---------------------------------------------------
    HEADER STYLE
----------------------------------------------------- */

.header {
  width: 100%;
}

.btn-menu {
  border-radius: 50%;
}

.navbar {
  color: $black;
  --bs-gutter-x: 0rem;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  background-color: $black;
  color: $light;
}

/* ---------------------------------------------------
    CONTENT CARD STYLE
----------------------------------------------------- */
.cardsContainer {
  color: $dark;
  background-color: $light;
  border-color: $dark;
  border-width: 5px;
  border-radius: 10px;

  h3 {
    color: $black;
  }

  &.list-priority {
    height: 48vh;
  }
}

//general
.list-group-item * {
  color: $dark;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.cardsContainer .legend {
  margin: 0 0;
  color: $dark;
  font-size: 10px;
  text-align: center;
}

.doughNut {
  width: 100%;
}

.card-container.first-component {
  // height: 60vh; faisait sortir du container
  // display: flex ; rétrécissait
  //taille espce occupé basis-<SENS
  // flex-direction: column;
  // flex-wrap: wrap;
  // justify-content: stretch;
  // gap: .5rem;

  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column wrap;
  height: 100%;

  .list-group-item {
    width: 24.2%;
    flex: 1 1;
    display: flex;
    padding: 0.5em;
    align-self: flex-start;
    min-height: 25%;
    margin-bottom: 2px;
    border-radius: 10px;

    &:hover {
      color: $black;
      cursor: pointer;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }

    > {
      div {
        display: flex;

        align-items: center;
        gap: 1rem;
      }
    }

    .big-title {
      font-weight: 700;
      color: #5c5c5c;
      font-size: 1.1em;
    }

    .info {
      margin-left: 0.5rem;
    }
  }
}

.cardsContainer .list-item:hover {
  h5,
  p {
    color: $black;
  }
}

.cardsContainer .list-item {
  padding: 0.2em 0.5em;
  align-items: center;
  cursor: pointer;

  h5 {
    font-size: 1em;
  }

  p {
    font-size: 13px;
    margin-bottom: 0;
  }
}

.colorDiv {
  border-radius: 50%;
  width: 2em;
  height: 2em;

  .danger {
    background-color: $danger !important;
  }
}

.cardsContainer .more-icon {
  border-color: $transparency;
  background-color: $transparency;
  cursor: pointer;
  margin: 0 auto;
  width: max-content;
}

/* ---------------------------------------------------
    Media list-group-item
----------------------------------------------------- */
@media screen and (max-width: 1427px) {
  .list-group-item h5 {
    font-size: 0.9rem;
  }
  .list-group-item > div div:last-child {
    padding-top: 5%;
  }
}

/* ---------------------------------------------------
    MAP CARD STYLE
----------------------------------------------------- */

.leaflet-container {
  width: 100%;
  height: 40em;
}

/* ---------------------------------------------------
    PAGES STYLE
----------------------------------------------------- */

.min-content {
  min-height: 96vh;
  //overflow-x: hidden;
}

@media screen and (max-width: 950px) {
  .min-content {
    min-height: 94vh;
    overflow-x: unset;
  }
}

/* ---------------------------------------------------
    PLANNING STYLE
----------------------------------------------------- */

.menu-planning {
  background-color: $light;
  min-height: 10vh;
  --bs-gutter-x: 0;
}

.menu-planning * .row {
  --bs-gutter-x: 0;
}

/* ---------------------
    INPUT STYLE
------------------------ */

.input-group {
  width: 71%;
  margin: 0 auto;
  position: relative;
}

.input-overlay {
  width: 100%;
  margin: 0 auto;
}

.suggestions {
  display: block;
  position: absolute;
  z-index: 100;
  background-color: #f6f6f6;
  border: 1px solid $dark;
  list-style: none;
  max-height: 70vh;
  overflow-y: auto;
  padding-left: 0;
  top: 100%;
  width: 98%;
  left: 1%;
}

.suggestions li {
  width: 100%;
  padding: 0.5rem;
  border-top: 1px solid $darker-light;

  .icons {
    color: $black;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  h6 {
    color: $black;
  }

  p {
    color: $dark;
    margin: 0;
  }
}

.suggestions li:first-child {
  border-top: 0px solid $dark;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.suggestion-active,
.suggestions li:hover {
  background-color: $darker-light;
  color: $dark;
  cursor: pointer;
  font-weight: 700;
}

.filter-suggestions {
  position: absolute;
  left: -19%;
  top: -50%;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 19%;

  select {
    border: 1px solid $dark;
    color: darken($darker-light, 30%);
    text-align: center;

    &:focus {
      color: #212529;
      background-color: #fff;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 0.25rem #0d6efd40;
    }

    option:first-child {
      color: $darker-light;
    }
  }
}

/* ---------------------
    INDIVIDUAL TIMELINE STYLE
------------------------ */

.tui-full-calendar-dayname.tui-full-calendar-today {
  color: $tertiary;
}

/* ---------------------
    AGENCY TIMELINE STYLE
------------------------ */

@keyframes slide {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: 113px;
  }
}

.rct-hl-even:hover,
.rct-hl-odd:hover {
  background: color.scale($darker-light, $lightness: -20%, $alpha: -50%) !important;
}
.rct-outer {
  background-color: $light;
  color: $dark;
}

.rct-item-content {
  color: $black;
  display: flex !important;
  align-items: center;
}

.rct-header-root {
  position: -webkit-sticky;
  position: sticky;
  top: 4vh;
  z-index: 100;
  background-color: $tertiary !important;
}

.rct-calendar-header {
  color: $black;
}

.rct-calendar-header *:hover {
  cursor: default !important;
}

.react-calendar-timeline .rct-scroll {
  overflow-x: hidden !important;
  cursor: default !important;
  display: flex !important;
}

.react-calendar-timeline .rct-outer {
  display: flex !important;
}

.rct-item-content {
  padding: 0 5px !important;
}

.rct-sidebar-row {
  color: $black;
  cursor: pointer;
  // padding: 5% 5px !important;
  line-height: 15px !important;
  white-space: pre-wrap !important;
  //min-height: 40px;
  display: inline-block;
  transition-property: color;
  transition-duration: 0.2s;
  font-size: 1rem !important;
  font-weight: 600;
  text-decoration: none;
  color: black;
  display: block;
  transform: perspective(1px) translateZ(0);
  position: relative;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $pacome-blue;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.4s;
    transition-timing-function: ease;
  }

  .last-name {
    position: relative;
    font-weight: 700;
  }

  &:hover {
    color: white;
    // transition-property: color;
    // transition-duration: 0.3s;
    transition: transform 0.1s ease-out 0s;
    transition: color 0.1s ease-out 0s;
  }

  &:hover:before,
  &:active:before {
    transform: scaleX(1);
  }

  .clickable {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 13px;
      text-align: center;
      margin: 0 0;
    }
  }
}

.rct-item.rtc-intervention {
  min-width: 19.5px !important;
  height: 19.5px !important;
  border-radius: 9.75px;
  margin-top: 0;
  background-color: $primary !important;
  border-color: $primary !important;

  &.success {
    background-color: #71c016 !important;
    border-color: #71c016 !important;
  }

  &.error {
    background-color: $danger !important;
    border-color: $danger !important;
  }
  &.cancelled-intervention {
    display: none;
    background-color: $dark !important;
    border-color: $dark !important;
    &.R {
      display: none !important;
    }
  }
}

.rct-item.rtc-intervention.R {
  .rct-item-content {
    color: white !important;
    font-weight: 600;
  }
}
.rct-item.rtc-intervention.P {
  .rct-item-content {
    color: white !important;
    font-weight: 600;
  }
}

.rct-item.rtc-booking {
  border-style: solid !important;
  border-width: 1px !important;
  border-radius: 4px;
}

.rct-item.rtc-booking.airbnb {
  background-color: $airbnb !important;
  border-color: darken($airbnb, 20%) !important;
}

.rct-item.rtc-booking.booking {
  background-color: $booking !important;
  border-color: $booking !important;
}

.rct-item.rtc-booking.homeaway {
  background-color: $homeaway !important;
  border-color: $homeaway !important;
}

.rct-item.rtc-booking.unavailable {
  background-color: $dark !important;
  border-color: $dark !important;
}

.rct-item.rtc-booking.back {
  background-color: $success !important;
  border-color: $success !important;
}

.rct-item.rtc-booking.rent {
  background-color: $rent !important;
  border-color: $rent !important;
}

.rct-item.rtc-booking.gdf {
  background-color: $warning !important;
  border-color: $warning !important;
}

.rct-item.rtc-booking.error {
  background-image: repeating-linear-gradient(45deg, transparent, transparent 40px, white 40px, white 80px) !important;

  animation: slide 2s linear infinite;
  will-change: background-position;
}

.rct-item:hover * {
  background-color: $light !important;
  border-radius: 20px;
}

.rct-item.rtc-booking:hover {
  border-color: $secondary !important;
}

.rct-item.rtc-intervention:hover {
  background-color: $primary !important;
  border-color: $secondary !important;
  .rct-item-content {
    color: black !important;
    font-weight: 700;
  }
}

.rct-item.rtc-intervention:hover * {
  border-radius: 10px !important;
}

/* ---------------------------------------------------
    IMAGES STYLE
----------------------------------------------------- */
.img404 {
  background: url("/Images/404/404.png") no-repeat center center fixed;
  background-size: cover;
  object-fit: cover;
  height: 95vh;
}

@media screen and (max-width: 1024px) {
  .img404 {
    background: url("/Images/404/404.png") no-repeat center;
    height: 95vh;
  }
}

@media screen and (max-width: 750px) {
  .img404 {
    background: url("/Images/404/404.png") no-repeat left;
    height: 91vh;
  }
}

/* ---------------------------------------------------
    POPOVER STYLE
----------------------------------------------------- */

.popover {
  position: absolute;
  padding: 0.5em;
  min-width: 276px;
  max-width: 550px;
}

.popover * {
  color: $black;
}

.popover *.danger {
  color: $danger;
}

.popover *.light {
  color: $light;
}

.popover ul li a {
  padding: 10px;
  font-size: 1.2em;
  display: block;
  color: $black;
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

/* ---------------------------------------------------
    SUBCOMPONENTS STYLE
----------------------------------------------------- */
.disableContent {
  pointer-events: none;
  opacity: 0.4;
}

.BooleanText.error {
  text {
    color: $danger;
  }

  small {
    color: $danger;
  }

  color: $danger;
}

/* ---------------------------------------------------
    HOME VIEW CARD
----------------------------------------------------- */

.home-view {
  .home-view-container1 {
    position: relative;
    width: 100%;
    height: 100%;

    .home-view-image-container {
      position: relative;
      padding-right: 0;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.3;
        z-index: 1;
      }

      > {
        img {
          width: 100%;
          max-width: 47.28vw;
          position: relative;
        }
      }

      h3 {
        color: #ffff;
        font-family: "Avenir Next Bold";
        font-size: 6rem;
        position: absolute;
        width: 95%;
        text-align: center;
        z-index: 2;
        text-shadow: 4px 3px 20px black;
        cursor: pointer;
        display: block;
        height: -webkit-fill-available;
        text-indent: 1em;
        padding-top: 23%;
        top: 0;
      }
    }

    p {
      flex: 2 0;
    }
  }

  hr {
    width: 100%;
    margin: 3% auto;
  }
}

.home-view-add-container {
  width: 100%;

  .home-view-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    vertical-align: middle; /* needed for Safari */

    .btn {
      border: $darker-light solid 2px;
      background-color: #eee;
      font-size: 20px;
      color: #777;

      &:focus {
        border-color: #71c016;
        box-shadow: none;
      }

      &:hover {
        background-color: #71c016;
        box-shadow: none;
      }
    }
  }

  .section {
    margin-top: 3%;
    border-top: 1px solid $dark;

    &:last-of-type {
      margin-bottom: 10vh;
    }

    .input-title {
      border-collapse: collapse;
      width: 100%;
      display: flex;

      div {
        border: 1px solid #ddd;
        vertical-align: middle; /* needed for Safari */
      }

      .area {
        width: 100%;
      }

      input {
        border: 0;
        display: block;
        width: 100%;
        padding: 8px;
        height: fit-content;
      }

      .btn-delete {
        border: $darker-light solid 2px;
        background-color: #eee;
        font-size: 17px;
        color: $danger;

        &:focus {
          border-color: $warning;
          box-shadow: none;
        }
      }
    }
    .category {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0rem 2rem;
      padding-bottom: 1rem;
      .name {
        width: 100%;
      }
      hr {
        width: 100%;
      }
      .input {
        margin-top: 1%;
        display: table;
        border-collapse: collapse;
        width: auto;

        div {
          display: table-cell;
          border: 1px solid #ddd;
          vertical-align: middle; /* needed for Safari */
        }

        .icon {
          background: #eee;
          color: #777;
          padding: 0 12px;
          width: 150px;
          text-align: center;
        }

        .area {
          width: auto;
          padding: 0 1rem;
        }

        input,
        textarea {
          border: 0;
          display: block;
          width: 100%;
          padding: 8px;
          resize: vertical;
        }
        textarea {
          height: 100%;
        }
        select {
          border: none;
          border-radius: 0;
        }
      }
      .type-textarea {
        width: 100%;
        .area {
          padding: 0;
        }
      }
      .type-dropdown {
        .area {
          padding: 0;
        }
      }
      .type-input {
        width: 100%;
      }

      .section-check {
        width: 100%;
        input {
          margin: 0 auto;
          display: block;
        }
      }
    }
  }

  .btn-add {
    width: 100%;
    border: $darker-light solid 2px;
    background-color: #eee;
    font-size: 20px;
    color: #777;

    &:focus {
      border-color: #71c016;
      box-shadow: none;
    }
  }
}

/* ---------------------------------------------------
    SLIDER LIGHTBOX
----------------------------------------------------- */
.lightbox {
  /* The Modal (background) */
  position: fixed;
  z-index: 1100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.55);

  /* The Close Button */
  .close {
    color: white;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;

    :hover,
    :focus {
      color: #999;
      text-decoration: none;
      cursor: pointer;
    }
  }

  /* Modal Content */
  .lightbox-content {
    position: relative;
    height: 60%;
    width: auto;
    max-width: 80%;
    margin: 5% auto;
    background-color: $transparency;

    img {
      height: -webkit-fill-available;
      display: block;
      object-fit: contain;
      margin: auto;
    }

    .lightbox-img-overlay {
      position: absolute;
      top: 0;
      height: -webkit-fill-available;
      margin: auto;
      transition: 0.5s ease;
      font-size: 20px;
      padding: 0;
      text-align: center;

      /* Number text (1/3 etc) */
      .number-text {
        color: #f2f2f2;
        background-color: #000000;
        width: auto;
        font-size: 12px;
        padding: 8px 12px;
        position: absolute;
        top: 0;
      }

      .prev,
      .next {
        cursor: pointer;
        position: absolute;
        top: 0;
        width: auto;
        height: 100%;
        padding: 16px;
        color: white;
        font-weight: bold;
        font-size: 40px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        user-select: none;
        -webkit-user-select: none;
        display: flex;
        place-items: center;
      }

      /* Position the "next button" to the right */
      .next {
        right: 0;
        border-radius: 3px 0 0 3px;
      }

      /* On hover, add a black background color with a little bit see-through */
      .prev:hover,
      .next:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }

  .lightbox-row {
    max-height: 30%;
    padding: 1%;
    background-color: $blackest;
    bottom: 0;
    width: 100%;
    position: absolute;
    place-content: center;
    transition: 1s ease-in-out;

    img {
      opacity: 0.6;
      max-width: 10%;
      margin: 0 0 0 1%;

      &:first-of-type {
        margin: 0;
      }

      &:hover {
        opacity: 1;
      }

      &.active {
        opacity: 1;
        outline: 1px solid white;
        outline-offset: 4px;
      }
    }
  }

  /* Caption text */
  .caption-container {
    text-align: center;
    background-color: black;
    padding: 2px 16px;
    color: white;
  }

  .active,
  .demo:hover {
    opacity: 1;
  }

  img.hover-shadow {
    transition: 0.3s;
  }

  .hover-shadow:hover {
    box-shadow:
      0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

/* ---------------------------------------------------
    CUSTOM TOOLTIPS
----------------------------------------------------- */

/* 
  You want a simple and fancy tooltip?
  Just copy all [data-tooltip] blocks:
*/
[data-tooltip] {
  position: relative;
  z-index: 100;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + 5px); /* 5px is the size of the arrow */
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 10px 18px;
  min-width: 50px;
  max-width: 800px;
  width: max-content;
  width: -moz-max-content;
  border-radius: 6px;
  font-size: 14px;
  background-color: rgba(59, 72, 80, 0.9);
  background-image: linear-gradient(30deg, rgba(59, 72, 80, 0.44), rgba(59, 68, 75, 0.44), rgba(60, 82, 88, 0.44));
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  white-space: pre-wrap;
  transform: translate(-50%, -5px) scale(0.5);
}

/* Tooltip arrow */
[data-tooltip]:after {
  content: "";
  border-style: solid;
  border-width: 5px 5px 0px 5px; /* CSS triangle */
  border-color: rgba(55, 64, 70, 0.9) transparent transparent transparent;
  transition-duration: 0s;
  /* If the mouse leaves the element,
                               the transition effects for the
                               tooltip arrow are "turned off" */
  transform-origin: top;
  /* Orientation setting for the
                               slide-down effect */
  transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}

/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
  transition-delay: 0.3s;
  transform: translate(-50%, -5px) scale(1);
}

/*
  Arrow slide down effect only on mouseenter (NOT on mouseleave)
*/
[data-tooltip]:hover:after {
  transition-delay: 0.5s; /* Starting after the grow effect */
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}

/*
  That's it for the basic tooltip.

  If you want some adjustability
  here are some orientation settings you can use:
*/

/* LEFT */
/* Tooltip + arrow */
[data-tooltip-location="left"]:before,
[data-tooltip-location="left"]:after {
  left: auto;
  right: calc(100% + 5px);
  bottom: 50%;
}

/* Tooltip */
[data-tooltip-location="left"]:before {
  transform: translate(-5px, 50%) scale(0.5);
}

[data-tooltip-location="left"]:hover:before {
  transform: translate(-5px, 50%) scale(1);
}

/* Arrow */
[data-tooltip-location="left"]:after {
  border-width: 5px 0px 5px 5px;
  border-color: transparent transparent transparent rgba(55, 64, 70, 0.9);
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}

[data-tooltip-location="left"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

/* RIGHT */
[data-tooltip-location="right"]:before,
[data-tooltip-location="right"]:after {
  left: calc(100% + 5px);
  bottom: 50%;
}

[data-tooltip-location="right"]:before {
  transform: translate(5px, 50%) scale(0.5);
}

[data-tooltip-location="right"]:hover:before {
  transform: translate(5px, 50%) scale(1);
}

[data-tooltip-location="right"]:after {
  border-width: 5px 5px 5px 0px;
  border-color: transparent rgba(55, 64, 70, 0.9) transparent transparent;
  transform-origin: right;
  transform: translateY(50%) scaleX(0);
}

[data-tooltip-location="right"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

/* BOTTOM */
[data-tooltip-location="bottom"]:before,
[data-tooltip-location="bottom"]:after {
  top: calc(100% + 5px);
  bottom: auto;
}

[data-tooltip-location="bottom"]:before {
  transform: translate(-50%, 5px) scale(0.5);
}

[data-tooltip-location="bottom"]:hover:before {
  transform: translate(-50%, 5px) scale(1);
}

[data-tooltip-location="bottom"]:after {
  border-width: 0px 5px 5px 5px;
  border-color: transparent transparent rgba(55, 64, 70, 0.9) transparent;
  transform-origin: bottom;
}

.booking-inventory {
  margin-top: 1.5rem;

  h2 {
    text-align: center;
    color: $pacome-blue;
    font-weight: 700;
    font-family: "Montserrat";
  }
  h4 {
    font-weight: 600;
    font-family: "Montserrat";
    color: $pacome-blue;
  }
  h5 {
    font-weight: 700;
    font-family: "Montserrat";
    color: $pacome-blue;
  }

  .inventory-notice {
    font-weight: 600;
    font-size: 1rem;
    font-family: "Montserrat";
    text-align: center;
    margin: 0 2rem;
  }
  .select-colour {
    font-family: "Montserrat";
    font-weight: 700;
    color: black !important;
    option {
      font-weight: 700;
    }
  }
  .hiddenInput {
    height: 1px;
    width: 1px;
    border: 0;
  }
  .imagesList {
    overflow: auto;
    width: 100%;
    img {
      object-fit: cover;
      max-height: 250px;
      height: auto;
      margin-right: 1rem;
    }
  }
}
