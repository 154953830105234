@import "/src/assets/scss/colors.scss";
.modify-home-sheet {
    .section {
        .category {
            .name {
                font-family: "Montserrat";
                font-size: 1.5rem;
            }
            .col.open {
                background-color: white;
                padding: 20px;
            }
        }
    }
}
.border-left {
    border-left: 1px solid;
    border-color: black;
}
