@import "../colors.scss";

.fancy-button {
    border: 1px solid $pacome-gold;
    transition: ease-out 0.5s;
    font-weight: 500;
    position: relative;
    color: white;
    &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        transition: 0.5s;
        top: -9px;
        left: -9px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
    }

    &::before {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        transition: 0.5s;
        bottom: -9px;
        right: -9px;
        border-bottom: 2px solid transparent;
        border-right: 2px solid transparent;
    }

    /* Font on hover */
    &:hover {
        color: white;
        font-weight: 500;
        background: $pacome-gold;
    }

    &:hover::after,
    &:hover::before {
        width: calc(100% + 18px) !important;
        height: calc(100% + 18px) !important;
        border-color: #222222;
        // border-radius: 15px;
    }

    /* color and size of the funky stuff */
    &:hover::after,
    &:hover::before {
        width: 40px;
        height: 40px;
        border-color: $pacome-gold;
    }

    &-success {
        background: #28a745;
        &:hover {
            background: #1e7e34;
        }
    }

    &-info {
        background: #17a2b8;
        &:hover {
            background: #138496;
        }
    }

    &-gray {
        background: #6c757d;
        &:hover {
            background: #5a6268;
        }
    }
}

.fancy-button-blue {
    border: 1px solid $pacome-blue;
    transition: ease-out 0.5s;
    font-weight: 500;
    position: relative;
    color: white;
    &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        transition: 0.5s;
        top: -9px;
        left: -9px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
    }

    &::before {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        transition: 0.5s;
        bottom: -9px;
        right: -9px;
        border-bottom: 2px solid transparent;
        border-right: 2px solid transparent;
    }

    /* Font on hover */
    &:hover {
        color: white;
        font-weight: 500;
        background: $pacome-blue;
    }

    &:hover::after,
    &:hover::before {
        width: calc(100% + 18px) !important;
        height: calc(100% + 18px) !important;
        border-color: #222222;
        // border-radius: 15px;
    }

    /* color and size of the funky stuff */
    &:hover::after,
    &:hover::before {
        width: 40px;
        height: 40px;
        border-color: $pacome-blue;
    }
}

.fancy-button-red {
    border: 2px solid red !important;
    transition: ease-out 0.5s;
    font-weight: 500;
    position: relative;
    color: red !important;
    &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        transition: 0.5s;
        top: -9px;
        left: -9px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
    }

    &::before {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        transition: 0.5s;
        bottom: -9px;
        right: -9px;
        border-bottom: 2px solid transparent;
        border-right: 2px solid transparent;
    }

    /* Font on hover */
    &:hover {
        color: white;
        font-weight: 500;
        background: red !important;
    }

    &:hover::after,
    &:hover::before {
        width: calc(100% + 18px) !important;
        height: calc(100% + 18px) !important;
        border-color: #222222;
        // border-radius: 15px;
    }

    /* color and size of the funky stuff */
    &:hover::after,
    &:hover::before {
        width: 40px;
        height: 40px;
        border-color: red !important;
    }
}

.modal-header {
    &.success {
        background: #28a745;
        .icon-box {
            border-color: #1e7e34;
            color: white;
            border: 2px solid white;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px auto;
        }
    }

    &.info {
        background: #17a2b8;
        .icon-box {
            border-color: white;
            color: white;
            border: 2px solid white;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px auto;
        }
    }

    &.danger {
        background: #dc3545;
        .icon-box {
            border-color: white;
            color: white;
            border: 2px solid white;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px auto;
        }
    }
}
