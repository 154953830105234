@import "../colors.scss";

.file-div {
    display: flex;
    flex-direction: column;
    .noFile {
        // text-align: left;
        font-weight: 700;
        font-size: 1rem;
    }
    a {
        color: $pacome-blue;
        text-decoration: none;
        position: relative;
        transition: all 0.2s ease-in-out;
        width: max-content;
        margin-bottom: 1rem;
        &::after {
            content: ">";
            margin-left: 5px;
        }
        &::before {
            content: "";
            background: $pacome-blue;
            width: 0px;
            height: 2px;
            position: absolute;
            bottom: -2px;
            left: 0;
            transition: all 0.2s ease-in-out;
        }
        &:hover::before {
            width: 100%;
            background: $pacome-blue;
        }
    }
}
