@import "../colors.scss";

// Bootstrap utility classes
.mb-3 {
    margin-bottom: 1rem !important;
}

.me-3 {
    margin-right: 1rem !important;
}

.d-flex {
    display: flex !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.top-50 {
    top: 50% !important;
}

.end-0 {
    right: 0 !important;
}

.translate-middle-y {
    transform: translateY(-50%) !important;
}

// Main popup styles
.billing-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    &.open {
        display: flex;
    }

    .container {
        background: white;
        padding: 20px;
        border-radius: 8px;
        width: 80%;
        // max-width: 1200px;
        max-height: 90vh;
        overflow-y: auto;
    }

    .billing-popup-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        h2 {
            margin: 0;
        }

        .close-button {
            background: none;
            border: none;
            font-size: 24px;
            cursor: pointer;
            padding: 0 8px;
            
            &:hover {
                color: $pacome-gold;
            }
        }
    }

    .popup-main-content {
        form {
            .single-line {
                margin-bottom: 1rem;
                
                .line-flex {
                    display: flex;
                    align-items: flex-start;
                    gap: 1rem;
                    flex-wrap: wrap;

                    > div {
                        flex: 1;
                        min-width: 200px;
                    }
                }

                h5 {
                    margin-bottom: 1rem;
                    font-weight: 500;
                }

                textarea.form-control {
                    min-height: 100px;
                    resize: vertical;
                }
            }
        }

        .form-label {
            display: block;
            margin-bottom: 0.5rem;
            font-weight: 500;

            &.required:after {
                content: "*";
                color: red;
                margin-left: 4px;
            }
        }

        .form-control, .form-select {
            width: 100%;
            padding: 0.375rem 0.75rem;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            font-size: 1rem;
            line-height: 1.5;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            
            &:focus {
                border-color: $pacome-blue;
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba($pacome-blue, 0.25);
            }
            
            &:disabled {
                background-color: #e9ecef;
                opacity: 1;
            }
        }

        hr {
            margin: 1rem 0;
            border: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
    }

    .down-buttons {
        button {
            color: white;
            background: $pacome-blue;
            margin-right: 1rem;
            transition: all 0.4s;
            border: none;
            box-shadow: 0 0 0px 0 $pacome-gold;
            &:hover {
                background: $pacome-gold;
                color: white;
                transition: all 0.2s;
                box-shadow: 0 0 8px 0 $pacome-gold;
            }
        }
    }
}

.pdfffff {
    width: 100%;
    display: flex;
    justify-content: center;
    iframe {
        width: 80%;
        height: 1000px;
        margin: 0 auto;
    }
}

.export-container {
    padding: 20px;
    
    .date-range-selector {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
        
        .form-group {
            flex: 1;
            
            label {
                display: block;
                margin-bottom: 5px;
                font-weight: 500;
            }
            
            input {
                width: 100%;
                padding: 0.375rem 0.75rem;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
            }
        }
    }
    
    .preview-table {
        margin: 20px 0;
        max-height: 400px;
        overflow-y: auto;
        
        h4 {
            margin-bottom: 15px;
        }
        
        table {
            width: 100%;
            border-collapse: collapse;
            
            th, td {
                padding: 8px 12px;
                border-bottom: 1px solid #eee;
                text-align: left;
            }
            
            th {
                background-color: #f8f9fa;
                font-weight: 600;
            }
            
            tr:hover {
                background-color: #f8f9fa;
            }
        }
    }
    
    .popup-actions {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #eee;

        button {
            padding: 0.375rem 0.75rem;
            border-radius: 0.25rem;
            border: 1px solid transparent;
            cursor: pointer;
            
            &.btn-secondary {
                background-color: #6c757d;
                color: white;
                
                &:hover {
                    background-color: #5a6268;
                }
            }
            
            &.btn-primary {
                background-color: $pacome-blue;
                color: white;
                
                &:hover {
                    background-color: darken($pacome-blue, 10%);
                }
                
                &:disabled {
                    background-color: lighten($pacome-blue, 20%);
                    cursor: not-allowed;
                }
            }
        }
    }
}

.export-dialog {
    padding: 20px;
    max-width: 1200px;
    width: 100%;
    background: white;
    border-radius: 8px;

    h3 {
        margin-bottom: 1.5rem;
        font-weight: 600;
    }

    .date-range-inputs {
        display: flex;
        gap: 1rem;
        margin-bottom: 1.5rem;
        align-items: flex-end;

        .form-group {
            flex: 1;
            max-width: 200px;
            
            label {
                display: block;
                margin-bottom: 0.5rem;
                font-weight: 500;
            }

            input {
                width: 100%;
                padding: 0.5rem;
                border: 1px solid #ced4da;
                border-radius: 4px;
                
                &:focus {
                    outline: none;
                    border-color: #80bdff;
                    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
                }
            }
        }
    }

    .dialog-actions {
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
        margin-bottom: 1.5rem;
        
        button {
            min-width: 120px;
            padding: 0.5rem 1rem;
            border-radius: 4px;
            font-weight: 500;
            
            &.btn-secondary {
                background: #6c757d;
                border: 1px solid #6c757d;
                color: white;
                
                &:hover {
                    background: #5a6268;
                    border-color: #545b62;
                }
            }
            
            &.btn-primary {
                background: $pacome-blue;
                border: 1px solid $pacome-blue;
                color: white;
                
                &:hover {
                    background: darken($pacome-blue, 7.5%);
                    border-color: darken($pacome-blue, 10%);
                }
                
                &:disabled {
                    background: lighten($pacome-blue, 20%);
                    border-color: lighten($pacome-blue, 20%);
                    cursor: not-allowed;
                }
            }
        }
    }

    .bills-preview {
        h4 {
            margin-bottom: 1rem;
            font-weight: 500;
        }
        
        .table-responsive {
            margin-top: 1rem;
        }
        
        .no-bills {
            text-align: center;
            padding: 2rem;
            color: #6c757d;
            background: #f8f9fa;
            border-radius: 4px;
            font-weight: 500;
        }
        
        table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;
            
            th {
                background-color: #f8f9fa;
                border-bottom: 2px solid #dee2e6;
                padding: 0.75rem;
                text-align: left;
                font-weight: 600;
                
                span {
                    display: inline-block;
                    padding: 0.25rem 0;
                }
            }
            
            td {
                padding: 0.75rem;
                border-bottom: 1px solid #dee2e6;
                vertical-align: middle;
            }
            
            tbody tr {
                &:hover {
                    background-color: rgba(0,0,0,0.075);
                }
            }
        }
    }
}
