@import "../colors.scss";

.products {
    .search-and-stuff {
        width: 80%;
        margin: 0 auto;
        padding: 2rem 0 0;

        .search {
            width: 33%;
            margin: 0 auto;

            input {
                border: none !important;
                border-radius: 8px;
                background: rgba($pacome-blue, 0.03);
                padding: 12px 16px;
                font-family: "Montserrat";
                color: #2c3e50;
                font-size: 0.95rem;

                &:focus {
                    outline: 2px solid rgba($pacome-blue, 0.1);
                    background: white;
                    box-shadow: 0 2px 8px rgba($pacome-blue, 0.05);
                }

                &::placeholder {
                    color: #526a7f;
                    opacity: 0.7;
                }
            }
        }

        // button {
        //     font-family: "Montserrat";
        //     font-weight: 500;
        //     font-size: 0.95rem;
        //     padding: 8px 12px;
        //     transition: all 0.2s ease;

        //     &:hover {
        //         color: $pacome-gold;
        //     }
        // }
    }

    .active-products,
    .inactive-products {
        margin: 0rem 0;

        h3 {
            margin-bottom: 2rem;
            color: $pacome-blue;
            width: 100%;
            text-align: center;
        }
    }

    tbody {
        tr {
            &:first-child {
                td {
                    &:first-child {
                        border-top-left-radius: 8px;
                        -moz-border-radius-topleft: 8px;
                    }
                    &:last-child {
                        border-top-right-radius: 8px;
                        -moz-border-radius-topright: 8px;
                    }
                }
            }

            &:last-child {
                td {
                    &:first-child {
                        border-bottom-left-radius: 8px;
                        -moz-border-radius-bottomleft: 8px;
                    }
                    &:last-child {
                        border-bottom-right-radius: 8px;
                        -moz-border-radius-bottomright: 8px;
                    }
                }
            }
        }
    }

    margin-bottom: 2rem;

    #product-sidebar {
        width: 400px;
        position: fixed;
        top: 0;
        right: -400px;
        height: 100vh;
        z-index: 1100;
        background: #ffffff;
        color: #8d8c8a;
        font-family: "Montserrat";
        transition: all 0.3s;
        box-shadow: -7px -1px 20px 0px rgba(0, 0, 0, 0.2);

        &.active {
            right: 0;
        }

        .header {
            padding: 1rem;
            background: $pacome-blue;
            display: flex;
            gap: 1rem;
            align-items: center;

            .close-sidebar,
            .edit-sidebar {
                border: none;
                background: transparent;
                width: 24px;
                height: 24px;
                flex-shrink: 0;
                padding: 0;

                svg {
                    width: 100%;
                    height: 100%;
                    fill: white;
                    transition: all 0.4s;
                    &:hover {
                        fill: $pacome-gold;
                        transition: all 0.2s;
                        rotate: -15deg;
                    }
                }
            }

            h2 {
                color: white;
                font-weight: 600;
                margin: 0;
                font-size: 1.25rem;
            }
        }

        .content {
            padding: 1rem;

            h2 {
                margin: 0.75rem 0;
                font-size: 1.1rem;
                font-weight: 600;
                color: $pacome-blue;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    margin: 0.5rem 0;
                    padding: 0.5rem 0;
                    color: black;
                    display: flex;
                    align-items: baseline;
                    gap: 0.75rem;
                    border-bottom: 1px solid rgba($pacome-blue, 0.1);
                    font-size: 0.95rem;

                    &:last-child {
                        border-bottom: none;
                    }

                    span {
                        &:first-child {
                            font-weight: 600;
                            min-width: 100px;
                            flex-shrink: 0;
                        }

                        &.value {
                            font-weight: normal;
                            flex: 1;
                            word-break: break-word;
                        }
                    }
                }
            }
        }
    }
}

.billing-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    z-index: 1000;

    &.open {
        opacity: 1;
        visibility: visible;
    }

    .container {
        background: white;
        padding: 2rem;
        border-radius: 12px;
        max-width: 90%;
        max-height: 90vh;
        overflow-y: auto;
        position: relative;

        .popup-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1.5rem;

            h3 {
                margin: 0;
            }

            .close-button {
                background: none;
                border: none;
                font-size: 1.5rem;
                line-height: 1;
                padding: 0.25rem 0.5rem;
                cursor: pointer;
                color: #666;
                transition: color 0.2s ease;

                &:hover {
                    color: $pacome-blue;
                }
            }
        }
    }
}

// Ensure pagination looks good
.pagination {
    button {
        min-width: 100px;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.6;
        }
    }

    span {
        min-width: 120px;
        text-align: center;
    }
}
