@import "/src/assets/scss/colors.scss";

#sidebar {
    background: white;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;

    .sidebar-header {
        background: $pacome-blue;
        flex-shrink: 0; /* Prevent header from shrinking */
        h3 {
            font-family: "Montserrat" !important;
            color: $pacome-gold !important;
            font-weight: 700;
            font-style: normal !important;
        }
        img {
            width: 80px;
            margin: 20px 0 30px;
        }
    }
    & > ul {
        flex: 1; /* Take remaining space */
        overflow-y: auto; /* Only this section scrolls */
        overflow-x: hidden;
        height: auto;
        scrollbar-width: thin;
        scrollbar-color: $pacome-blue transparent;

        /* Webkit scrollbar styles */
        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, 0.05);
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            transition: background 0.3s ease;
            
            &:hover {
                background: rgba(0, 0, 0, 0.3);
            }
        }

        li {
            .header-link,
            .sidebar-dropdown {
                display: inline-block;
                transition-property: color;
                transition-duration: 0.4s;
                font-size: 1.1rem !important;
                font-family: "Montserrat", sans-serif;
                text-decoration: none;
                color: black;
                display: block;
                transform: perspective(1px) translateZ(0);
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: $pacome-blue;
                    transform: scaleX(0);
                    transform-origin: 0 50%;
                    transition-property: transform;
                    transition-duration: 0.7s;
                    transition-timing-function: ease;
                }
                span {
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        left: 0;
                        right: 100%;
                        bottom: -5px;
                        background: $pacome-gold;
                        height: 3px;
                        transition-property: right;
                        transition-duration: 0.5s;
                        transition-timing-function: ease-out;
                        border-radius: 100px;
                    }
                }

                &:hover span::after {
                    right: 0;
                    transition-property: right;
                    transition-duration: 0.4s;
                    transition-timing-function: ease-out;
                }

                &:hover {
                    color: white;
                    // transition-property: color;
                    // transition-duration: 0.3s;
                    transition: transform 0.3s ease-out 0s;
                    transition: color 0.3s ease-out 0s;
                    svg {
                        color: white;
                        transition-property: transform;
                        transition-duration: 0.3s;
                    }
                }

                &:hover:before,
                &:active:before {
                    transform: scaleX(1);
                }

                svg {
                    width: 20px;
                    color: black;
                    margin-right: 25px;
                }
            }
        }
        li.active {
            .header-link,
            .sidebar-dropdown {
                color: white;
                font-weight: 600;
                background: $pacome-blue;
                span {
                    &:after {
                        right: 0;
                    }
                }
                svg {
                    color: white;
                }
            }
        }
    }
    .pageSubmenuParent {
        #pageSubmenu a {
            background: white;
            padding: 10px 0;
            margin: 0 auto;
            position: relative;

            &:hover {
                transition: all 0.3s ease-out;
                color: white;
                font-weight: 600;

                svg {
                    color: $pacome-gold;
                }
            }
        }
    }
}
