@import "/src/assets/scss/colors.scss";
.accordion {
    border-radius: 10px;
    margin-bottom: 10px;
    filter: drop-shadow(1px 2px 6px rgba(0, 0, 0, 0.07));

    .accordion-header {
        background-color: #fff;
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: 0.25s ease-out;
        transform: scale(1);
        h2 {
            margin: 0;
        }

        &:hover {
            transform: scale(1);
            // transition: 0.2s ease-out !important;
            background-color: $pacome-blue;
            color: white;
        }
    }
    .accordion-header.open {
        background-color: $pacome-blue;
        color: white;
        transform: scale(1);

        &:hover {
            opacity: 0.8;
        }
    }

    .accordion-body {
        padding: 0px 10px;
        max-height: 0;
        overflow: hidden;
        transition: 0.5s ease-out;
        .row {
            justify-content: center;
            .col.open {
                border-radius: 10px;
            }
        }
    }

    .accordion-body.open {
        max-height: 1000vh;
        padding: 10px;
        transition: 0.3s ease-out;
    }
}

.btn-accordion {
    filter: drop-shadow(1px 2px 6px rgba(0, 0, 0, 0.06));
    transition: 0.6s ease-out;
    transform: scale(0.9);
    &:hover {
        transform: scale(1);
        transition: 0.2s ease-out !important;
    }
    &.mx-2 {
        aspect-ratio: 1;
    }
    &.vertical {
        display: flex;
        align-items: center;
    }
}
