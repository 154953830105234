@import "/src/assets/scss/colors.scss";

.reviews {
    & > div {
        display: flex;
        flex-direction: column;
    }
    label {
        font-size: 1.25rem;
        margin: 0.5rem 1rem;
    }
    .h-full {
        height: 100%;
    }
    .rating {
        --dir: to right; /* Ensure --dir is a valid direction */
        --fill: #faba79;
        --fillbg: rgba(100, 100, 100, 0.15);
        --star: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 17.25l-6.188 3.75 1.641-7.031-5.438-4.734 7.172-0.609 2.813-6.609 2.813 6.609 7.172 0.609-5.438 4.734 1.641 7.031z"/></svg>');
        --stars: 5;
        --starsize: 3rem;
        --symbol: var(--star);
        --value: 1;
        --w: calc(var(--stars) * var(--starsize));
        --x: calc(100% * (var(--value) / var(--stars)));
        block-size: var(--starsize);
        inline-size: var(--w);
        position: relative;
        touch-action: manipulation;
        -webkit-appearance: none;
        border-radius: 10px;
        margin: 0.5rem 1rem;
    }

    .rating::-moz-range-track {
        background: linear-gradient(var(--dir), var(--fill) 0 var(--x), var(--fillbg) 0 var(--x)); /* Removed 'to' */
        block-size: 100%;
        mask: repeat left center/var(--starsize) var(--symbol);
    }
    .rating::-webkit-slider-runnable-track {
        background: linear-gradient(var(--dir), var(--fill) 0 var(--x), var(--fillbg) 0 var(--x)); /* Removed 'to' */
        block-size: 100%;
        mask: repeat left center/var(--starsize) var(--symbol);
        -webkit-mask: repeat left center/var(--starsize) var(--symbol);
    }

    .rating::-moz-range-thumb {
        height: var(--starsize);
        opacity: 0;
        width: var(--starsize);
    }
    .rating::-webkit-slider-thumb {
        height: var(--starsize);
        opacity: 0;
        width: var(--starsize);
        -webkit-appearance: none;
    }
}
