@import "./colors.scss";

.fullscreen-gallery-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullscreen-gallery-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    z-index: 10;
    padding: 8px;
    border-radius: 50%;
    transition: background-color 0.2s;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
}

.gallery-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 40px;
}

.nav-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px;
    border-radius: 50%;
    transition: background-color 0.2s;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    &.prev {
        margin-right: 20px;
    }

    &.next {
        margin-left: 20px;
    }
}

.image-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 80vh;
    max-width: 80vw;
}

.gallery-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}

.gallery-counter {
    position: absolute;
    bottom: 20px;
    color: white;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 4px 12px;
    border-radius: 12px;
}

.images-fits-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  overflow-x: auto;
  padding: 10px 0;
  z-index: 10051;
  
  > div {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
    
    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      
      .overlay-buttons {
        opacity: 1;
      }
    }
    
    img {
      object-fit: cover;
      width: 150px;
      height: 150px;
      display: block;
      
      &.landscape {
        height: auto;
        max-height: 150px;
      }
      
      &.portrait {
        width: auto;
        max-width: 150px;
      }
    }
    
    &.landscape {
      img {
        height: auto;
        max-height: 150px;
      }
    }
    
    &.portrait {
      img {
        width: auto;
        max-width: 150px;
      }
    }
    
    .overlay-buttons {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: space-between;
      padding: 8px;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: opacity 0.2s ease;
      
      button {
        background: transparent;
        border: none;
        color: white;
        cursor: pointer;
        padding: 5px 10px;
        border-radius: 4px;
        transition: background 0.2s ease;
        
        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }
        
        &.delete-btn:hover {
          color: #ff4d4d;
        }
        
        &.move-btn:hover {
          color: #4da6ff;
        }
      }
    }
  }
}

// Override styles for react-image-lightbox
.ReactModalPortal {
    & > div {
        z-index: 10051!important;
    }
  .ril__outer {
    background-color: rgba(0, 0, 0, 0.85);
  }
  
  .ril__toolbar {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .ril__toolbarItem {
    padding: 8px 10px;
  }
  
  .ril__builtinButton {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
  
  .ril__navButtons {
    padding: 15px;
  }
  
  .ril__captionContent {
    font-size: 16px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 4px;
  }
} 