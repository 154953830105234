@import "/src/assets/scss/colors.scss";

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2 {
        margin: 0;
        color: $pacome-blue;
    }

    .close-button {
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
        color: #666;

        &:hover {
            color: #333;
        }
    }
}

.search-container {
    margin-bottom: 20px;

    .search-input {
        width: 100%;
        padding: 8px 12px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 14px;

        &:focus {
            outline: none;
            border-color: $pacome-blue;
            box-shadow: 0 0 0 2px rgba($pacome-blue, 0.1);
        }
    }
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
}

.products-list {
    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;

        th,
        td {
            padding: 12px;
            text-align: left;
            border-bottom: 1px solid #eee;
        }

        th {
            background-color: #f8f9fa;
            color: $pacome-blue;
            font-weight: 600;
        }

        td {
            vertical-align: middle;
        }

        .add-button {
            background-color: $pacome-blue;
            color: white;
            border: none;
            border-radius: 4px;
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
                background-color: darken($pacome-blue, 10%);
                transform: scale(1.05);
            }
        }
    }
}
