@import "/src/assets/scss/colors.scss";

.planning-parent {
    background-color: white;
    margin: -1.5rem 0 0 !important;
    // padding-top: 1.5rem;
    display: grid;
    grid-template-columns: 170px 170px 40dvw 42.12dvw;
    grid-template-rows: 4rem auto auto;
    gap: 0px 0px;
    grid-template-areas:
        "drop-month drop-type date kpi"
        "calendar calendar calendar calendar";
    .menu-planning {
        display: contents;
    }
    .drop-type {
        grid-area: drop-type;
        position: relative;
    }
    .drop-month {
        grid-area: drop-month;
        position: relative;
    }
    .date {
        grid-area: date;
    }
    .kpi {
        grid-area: kpi;
        z-index: 1000;
        margin-top: 0.5rem;
    }
    .react-calendar-timeline,
    .full-width-grid {
        grid-area: calendar;
    }
    .loading-planning {
        grid-area: calendar;
        margin: 2.5rem;
    }
    .menu-planning {
        // background-color: $pacome-blue;
        .col.d-none {
            .accordion {
                color: $pacome-blue;
            }
        }
    }
    & > h3 {
        font-family: "Montserrat";
        font-size: 25px;
        font-weight: 700;
        text-align: center;
        position: sticky;
        top: 10px;
        z-index: 1020;
        color: wwhite;
        pointer-events: none;
        color: $pacome-blue;
        align-self: center;
        margin: 0;
    }

    #dropdownMenuLink {
        color: white;
        font-family: "Montserrat";
        font-weight: 700;
        border: none;
        background: $pacome-blue;
        box-sizing: border-box;
        height: 100%;
        border-radius: 0;
        position: relative;
        &:hover {
            color: $pacome-blue;
            background-color: $pacome-gold;
            box-shadow: inset 0px 0px 0px 3px $pacome-gold;
        }
    }

    .dropdown-menu.show {
        border-radius: 0;
        background: $pacome-blue;
        margin-top: -2px !important;
        transition: all 0.3s ease-in-out;
        width: 100%;
        button {
            color: white;
            transition: all 0.1s ease-in-out;
            background: $pacome-blue;
            &:hover {
                background: $pacome-gold;
                font-weight: 700;
            }
            &.disabled {
                opacity: 0.5;
            }
        }
    }

    .react-calendar-timeline {
        .rct-sidebar {
            .rct-sidebar-row {
                border-bottom: unset;
            }
        }
        .rct-header-root {
            border: none !important;
            padding: 0 !important;
            top: 3rem;
            .btn {
                padding-top: 6px !important;
                padding-bottom: 6px !important;
                border: none;
                border-radius: 0;
                background: $pacome-gold;
                color: white;
                font-weight: 700;
                &:hover {
                    background: white;
                    color: $pacome-gold;
                }
            }
        }
        .rct-calendar-header {
            border: none;
            div {
                div {
                    div {
                        background: $pacome-blue;
                        color: white;
                        font-family: "Montserrat";
                        font-weight: 600;
                    }
                }
            }
        }
        .rct-day-0.rct-vl,
        .rct-day-6.rct-vl {
            background: #00000011 !important;
        }
        .rct-vertical-lines .rct-vl {
            border-left: 1px solid #0000001c;
        }
        .rct-hl-odd {
            background: #e5e5e5;
        }
        .rct-hl-even {
            background: white;
        }
        .rct-hl-even,
        .rct-hl-odd {
            border-bottom: unset !important;
        }
        .rct-hl-even:hover,
        .rct-hl-odd:hover {
            background: #00000022 !important;
        }

        .rtc-booking {
            border-radius: 25px;
            border-width: 3px !important;
            // border: none !important;

            .rct-item-content {
                font-family: "Montserrat";
                font-weight: 700;
                border-radius: 0;
                // capitalize first letter
                text-transform: capitalize;
                transition: 0.2s ease-in-out all;
                text-decoration: none;
                color: black;
                border-radius: 25px;

                &:hover {
                    background: white !important;
                    font-weight: 700;
                    text-decoration: underline;
                }
            }
        }
    }
}

div.rct-item.rtc-booking.Rent-Client {
    background: $pacome-blue !important;
    border-color: $pacome-blue !important;
    color: white;
    .rct-item-content {
        color: white;
    }
}
