@import "/src/assets/scss/colors.scss";

.parent {
    .background {
        filter: saturate(1.5) blur(4px);
        background-image: url("https://i.ibb.co/C5KK8Td/DJI-20230607-155726-796-1-1.webp");
    }
    .loginBox {
        background: $pacome-blue;
        border-radius: 20px;
        filter: saturate(1);
        h3 {
            font-family: "Montserrat" !important;
            color: $pacome-gold !important;
            font-weight: 700;
            font-style: normal !important;
            margin: 0;
        }
        h4 {
            margin: 0 0 20px;
            font-family: "Montserrat" !important;
            // color: $pacome-gold !important;
            font-weight: 700;
            font-style: normal !important;
            font-size: 1rem;
            font-style: italic;
        }
        img {
            width: 80px;
            margin: 20px 0 30px;
        }

        .fancy-button {
            border: 1px solid $pacome-gold;
            transition: ease-out 0.5s;
            font-weight: 500;
            position: relative;
            color: white;
            &::after {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                transition: 0.5s;
                top: -9px;
                left: -9px;
                border-top: 2px solid transparent;
                border-left: 2px solid transparent;
            }

            &::before {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                transition: 0.5s;
                bottom: -9px;
                right: -9px;
                border-bottom: 2px solid transparent;
                border-right: 2px solid transparent;
            }

            /* Font on hover */
            &:hover {
                color: white;
                font-weight: 500;
                background: $pacome-gold;
            }

            &:hover::after,
            &:hover::before {
                width: calc(100% + 18px) !important;
                height: calc(100% + 18px) !important;
                border-color: #222222;
                // border-radius: 15px;
            }

            /* color and size of the funky stuff */
            &:hover::after,
            &:hover::before {
                width: 40px;
                height: 40px;
                border-color: $pacome-gold;
            }
        }
    }
}
